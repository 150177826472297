// variables
$mobile: 768px;
$headerFont: "Days One", sans-serif;
$bodyFont: "Open Sans", sans-serif;
$overlay: #00000017;


$form-field-border: black;
// $form-field-border-focus: blue;
// $action-color-dark: pink;


$startColor: #ff5092; // pink
$midColor: #FF9182; // sunset orange
$midColor2: #ffbc74; // darker gold
$endColor: #ffd869; // bright gold

$linkColor: #e31e68; // darker pink for contrast
$linkHoverColor: #FF9182;

$textColor: #0a0a0a;

$lighterGray: #4a4a4a; // icons in footer, "Menu" on mobile

$formFieldBorder: #ddd;
$formFieldBorderFocus: #FF9182;

$buttonColor: #ff5092;
$buttonHoverColor: #FF9182;


$largePageWidth: 1000;
$smallPageWidth: 700;

$pagePadding: 20; // padding on left and right sides of page

$bodyFont: "Open Sans", serif;
$headerFont: "Days One", serif;
$secondaryFont: "Catamaran", sans-serif;

$menuHeight: 79;