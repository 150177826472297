@import "./variables.scss";

.subhead {
    font-family: $headerFont ;
    font-size: 1.6em;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.projectHeader {
    text-align: center;
    margin-bottom: 2em;

    @media (max-width: $mobile ) {
        margin-top: 0; //ugh this is a weird way to cancel out css in fullwidthimg
    }
}

.secondaryFont {
    font-size: 10px;
    font-weight: bold;
    font-family: "Catamaran",
        sans-serif;
    text-transform: uppercase;
}

.backgroundImg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.boxShadow {
    -webkit-box-shadow: 0px 4px 20px 4px rgba(217, 217, 217, 0.6);
    box-shadow: 0px 4px 20px 4px rgba(217, 217, 217, 0.6);
}

.textGradient {
    // $textGradient
}

.button {
    border: none;
    border-radius: 5px;
    background: $buttonColor;
    font-size: 14px;
    font-family: $headerFont;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    width: auto;
    padding: 12px 50px;
    margin: 20px auto 0;
    transition: 0.3s opacity;
    -webkit-appearance: none;

    &:hover {
        color: #fff;
        background: $buttonHoverColor
    }
}

// images
.imageInRowWrapper {
    display: flex;
    width: 150%;
    position: relative;
    left: -25%;
    margin-top: 20px;

    @media (max-width: calc(1.5 * $smallPageWidth - $pagePadding)) {
        width: 100%;
        left: 0;
    }
}

.imageInRow {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: column;
    margin: 0 5px;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.fullWidthImg {
    margin-bottom: 30px;
    width: 150%;
    position: relative;
    left: -25%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media (max-width: (1.5 * $smallPageWidth - $pagePadding)) {
        width: 100%;
        left: 0;
    }
}

.rightImg {
    // $leftRightImg
    float: right;
    margin-left: calc(-25% + 20px);
    right: -25%;

    @media (max-width: (1.5 * $smallPageWidth - $pagePadding)) {
        margin-left: 20px;
        right: 0;
    }
}

.leftImg {
    // helper.leftRightImg
    float: left;
    margin-right: calc(-25% + 20px);
    left: -25%;

    @media (max-width:(1.5 * $smallPageWidth - $pagePadding)) {
        margin-right: 20px;
        left: 0;
    }
}